<template>
  <div>
    <div class="section agb-section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <base-title>AGB</base-title>
          </div>
        </div>
      </div>
      <div class="separator separator-primary"></div>
      <div class="container">
        <div class="section-story-overview">
          <div class="row">
            <h3>
              Geltungsbereich der AGB
            </h3>
            <p>
              Die vorliegenden AGB sind für alle Lieferungen, Dienstleistungen und Installationen der
              ALKA
              AG (nachfolgend «Gesellschaft» genannt) gültig. Vorhandene und eigene
              Geschäftsbedingungen
              des Auftraggebers, Bestellers oder Käufers (nachfolgend «Besteller» genannt) werden
              wegbedungen.
            </p>
            <h3>
              Preise
            </h3>
            <p>
              Alle Preisangaben verstehen sich rein netto exkl. MwSt. und in Schweizer Franken (CHF).
              Allfällige Preisänderungen auf Grund von Währungsschwankungen oder Technologiewandel
              bleiben
              vorbehalten.
            </p>
            <h3>
              Leistungsumfang
            </h3>
            <p>
              Der Leistungsumfang ist in der Auftragsbestätigung respektive im Werkvertrag festgelegt.
              Nicht enthaltene Leistungen werden zu den bei der Ausführung gültigen Preisen zusätzlich
              verrechnet.
            </p>
            <h3>
              Mehraufwand in Folge mangelnder Koordination
            </h3>
            <p>
              Die Verantwortung für die Koordination der verschiedenen Unternehmen im Bauvorhaben
              liegt
              beim Besteller resp. bei der Bauleitung. Mehraufwand in Folge mangelnder Koordination
              wird
              separat verrechnet.
            </p>
            <h3>Mengenangaben im Angebot
            </h3>
            <p>
              Die im Angebot aufgeführten Mengenangaben (Stk., m, etc.) sind approximativ. Das heisst,
              sie
              können unter- oder überschritten werden, ohne dass der Besteller Änderungsansprüche an
              die
              Einheitspreise geltend machen kann. Die Mengenangaben gelten als Kalkulationsgrundlage
              für
              das von der Gesellschaft gemachte Angebot.
            </p>
            <h3>
              Lieferfristen/ Lieferungen
            </h3>
            <p>
              Angegebene Lieferfristen von Produkten und Materialien sind unverbindliche Richtangaben.
              Massgebend sind die Herstellerangaben, welche kurzfristig ändern können. Der Versand von
              Produkten und Materialien erfolgt auf Gefahr des Bestellers.
            </p>
            <h3>Lieferungen Bauseits</h3>
            <br>
            <p>
              Die Gesellschaft übernimmt keine Haftung für bauseits gelieferte Produkte und
              Materialien, sofern nichts anderes vertraglich vereinbart wurde.
            </p>
            <h3>
              Termine
            </h3>
            <p> Kann der Besteller die notwendigen Voraussetzungen für eine termingerechte Erfüllung
              gemäss Vertrag nicht gewährleisten, ist die Gesellschaft ihrerseits von der
              Einhaltung
              der vereinbarten Termine entbunden.
            </p>
            <h3>
              Eigentumsvorbehalt
            </h3>
            <p>Das Eigentum an Produkte und Materialien geht erst mit der vollständigen Bezahlung des im
              Vertrag vereinbarten Preises auf den Besteller über. Die Gesellschaft ist zur Eintragung
              des
              Eigentumsvorbehalts im Register ermächtigt, solange die Zahlung nicht vollständig
              geleistet
              ist.
            </p>
            <h3>
              Abnahme, Prüfung und Mängelrüge
            </h3>
            <p>Der Besteller ist verpflichtet, die von der Gesellschaft gelieferten Produkte,
              Materialien
              und Installationen sofort nach Erhalt oder Abholung bzw. nach Übergabe zu prüfen und
              allfällige Mängel sofort schriftlich anzuzeigen. Die sofortige Rügepflicht gilt auch für
              alle Dienstleistungen. Mängel, die bei sorgfältiger Prüfung nicht erkennbar waren, sind
              sofort nach deren Entdeckung schriftlich anzuzeigen.
              <br>
              Unterlässt der Besteller seine Prüfungspflicht, gilt die Lieferung als vorbehaltlos
              akzeptiert.
              <br>
              Die Mängelbehebung erfolgt innert angemessener Zeit.
            </p>
            <h3>
              Eigentums- und Immaterialgüterrecht
            </h3>
            <p>Das Eigentums- und Immaterialgüterrecht an allen von der Gesellschaft erstellten
              Offerten,
              Dokumentationen, Projekten, Zeichnungen, Schemata, Plänen, Berechnungen und an sonstigen
              Unterlagen bleibt bei der Gesellschaft. Sie dürfen Drittpersonen, insbesondere
              Mitbewerbern,
              nicht zugänglich gemacht und abgegeben werden. Im Übertretungsfalle ist die Gesellschaft
              berechtigt, eine Konventionalstrafe in der Höhe von 10% der Offertsumme einzufordern.
            </p>
            <h3>
              Zahlungsbedingungen
            </h3>
            <p>Die Zahlungsfrist beträgt 30 Tage rein netto ab Rechnungsdatum. Gerät der Besteller in
              Verzug, so hat die Gesellschaft Anspruch auf 5% Verzugszins sowie Mahn-, Inkasso-,
              Anwalts-
              und Gerichtskosten. Die Mahngebühr beträgt CHF 20.00 ab der zweiten Mahnung. Weiter ist
              die
              Gesellschaft berechtigt, sämtliche Leistungen unverzüglich und ohne weitere Mitteilung
              einzustellen. Ist der Besteller mit der Bezahlung in Verzug, so hat die Gesellschaft
              schliesslich das Recht, unverzüglich vom Vertrag zurückzutreten.
            </p>
            <h3>
              Haftung
            </h3>
            <p>Die Gesellschaft haftet nur für Sach- und Personenschäden, die durch Vorsatz oder grobe
              Fahrlässigkeit entstanden sind. Im Übrigen wird die Haftung wegbedungen. Des Weiteren
              haftet
              die Gesellschaft nicht für entgangenen Gewinn, ausgebliebene Einsparungen, Schäden aus
              Ansprüchen Dritter sowie andere Folgeschäden. Schliesslich haftet die Gesellschaft auch
              nicht für Schäden entstanden aufgrund höherer Gewalt wie z.B. Naturkatastrophen, Streik,
              Aussperrung, Unruhen, Ein- und Ausfuhrverbote, Terrorakte, Energie- und Rohstoffmängel
              etc.
            </p>
            <h3>
              Diebstahl
            </h3>
            <p>Nutzen und Gefahr von Produkten und Materialien gehen mit dem Einbau oder der Montage auf
              den
              Besteller über. Die Gesellschaft haftet nicht für montiertes oder eingebautes Material,
              welches von Dritten entwendet wird. Die Kosten für den Materialersatz sowie allfällige
              Installationskosten sind vom Besteller zu tragen.
            </p>
            <h3>
              Gewährleistung
            </h3>
            <p>Die Gewährleistungsdauer beträgt 24 Monate ab Abnahme. Für Produkte- und
              Materiallieferungen
              gelten die entsprechenden Gewährleistungsbedingungen der Hersteller auch gegenüber dem
              Besteller.
            </p>
            <h3>
              Datenschutz und Geheimhaltung
            </h3>
            <p>Die Gesellschaft verpflichtet sich, die Bestimmungen des Datenschutzes einzuhalten und
              Kundendaten sorgfältig zu bearbeiten. Wenn nichts anderes vertraglich vereinbart ist,
              ist
              die Gesellschaft berechtigt, den Besteller als Referenz gegenüber potentiellen Kunden zu
              verwenden.
            </p>
            <h3>
              Gerichtsstand und andwendbares Recht
            </h3>
            <p>Das Rechtsverhältnis untersteht dem schweizerischen Recht. Die Anwendung des Wiener
              Übereinkommens über Verträge über den internationalen Warenkauf (Wiener Kaufrecht) wird
              vollumfänglich ausgeschlossen. Streitigkeiten zwischen der Gesellschaft und dem
              Besteller
              werden von den ordentlichen Gerichten beurteilt. Gerichtsstand ist Opfikon. Die
              Gesellschaft
              behält sich vor, ihre Rechte auch am Domizil des Bestellers geltend zu machen.</p>
          </div>
          <br><br>
          <div class="row">
            <p>CH-8051 Zuerich, 30.12.2020</p>
          </div>
          <br>
          <div class="row">
            <div class="copyright font-weight-bold">
              &copy; {{ year }} A-Z Totalunternehmunf GmbH & DIBA GmbH
            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
    </div>
</template>
<script>

  export default {
    bodyClass: 'landing-page',
    name: 'AGB',
  }
</script>
<style scoped>
.agb-section{
  background-color:#F2F1F1!important;
}
@media screen and (max-width: 992px) {
  .v-application p {
    font-size: 0.9rem!important;
    line-height:1.2rem!important;
  }
}
</style>

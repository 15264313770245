import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#c0bdbb',
        secondary: '#d9d7d6',
        accent: '#f4f3f3',
        info: '#3A3937',

      },
    },
  },
})

<template>
  <component
    :is="tag"
    class="text-h3 v-heading mb-3 font-weight-normal"
  >
    <slot />
  </component>
</template>

<script>
  export default {
    name: 'BaseSubheading',

    props: {
      tag: {
        type: String,
        default: 'h3',

      },
    },
  }
</script>

<style >

.v-application h3.v-heading{
  letter-spacing: 3px !important;
  font-size: 4vw!important;
  line-height: 3vw!important;
}
@media screen and (max-width: 992px) {
  .v-application h3.v-heading {
    font-size:1.7rem!important;
    line-height: 1.7rem!important;
  }
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import View from '@/components/core/View'
import AppBar from '@/components/core/AppBar'
import Footer from '@/components/core/Footer'
import AGB from '@/components/AGB'
import Impressum from '@/components/Impressum'

Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'index',
      components: { default: View, header: AppBar, footer: Footer },
      props: {

      },
    },
    {
      path: '/agb',
      name: 'agb',
      components: { default: AGB, header: AppBar, footer: Footer },
      props: {
        header: { colorOnScroll: 400, transparent: false },
        footer: { backgroundColor: 'black' },
      },
    },

    {
      path: '/Impressum',
      name: 'Impressum',
      components: { default: Impressum, header: AppBar, footer: Footer },
      props: {
        header: { colorOnScroll: 400, transparent: false },
        footer: { backgroundColor: 'black' },
      },
    },
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash, offset: { x: 0, y: 90 } }
    } else {
      return { x: 0, y: 0 }
    }
  },
})

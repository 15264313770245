import Vue from 'vue'
import BaseBtn from '@/components/base/Btn'
import BaseHeading from '@/components/base/Heading'
import BaseSubheading from '@/components/base/Subheading'
import BaseText from '@/components/base/Text'
import BaseTitle from '@/components/base/Title'

Vue.component('BaseBtn', BaseBtn)
Vue.component('BaseHeading', BaseHeading)
Vue.component('BaseSubheading', BaseSubheading)
Vue.component('BaseText', BaseText)
Vue.component('BaseTitle', BaseTitle)

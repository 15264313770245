<template>
  <p
    class="text-h6 font-weight-light"
    style="line-height: 1.7 !important;"
  >
    <slot />
  </p>
</template>

<script>
  export default {
    name: 'BaseText',
  }
</script>
<style>
@media screen and (max-width: 992px) {
  .v-application p {
    font-size: 1.4rem!important;
    line-height:1.4rem!important;
  }
}
</style>

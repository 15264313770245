<template>
  <v-footer
    height="88"
    class="justify-left info"
  >
    <social-media large />
    <v-divider
      vertical
    />
    <v-row
      justify="right"
      no-gutters
    >
      <v-col
        class="text-left"
        cols="8"
      >
      <v-btn
        v-for="link in links"
        :key="link"
        :to="link"
        color="white"
        text
        rounded
        class="my-2"
      >
        {{ link }}
      </v-btn>
      </v-col>
      <v-col
        class="text-right mt-3"
        cols="4"
      >
        {{ new Date().getFullYear() }} — <strong>&copy; A-Z Totalunternehmung GmbH
      </strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
  export default {
    name: 'CoreFooter',

    components: {
      SocialMedia: () => import('@/components/SocialMedia'),
    },
    data: () => ({
      links: [
        'Impressum',
        'AGB',
      ],
    }),
  }
</script>

<style scoped>
.v-application p{
 color:white;
  font-weight: bold;
}
@media screen and (max-width: 992px) {
  .v-application p {
    font-size: 0.9rem!important;
    line-height:1.2rem!important;
  }
}
</style>

<template>
  <v-app-bar
    v-scroll="onScroll"
    :color="!isScrolling ? 'transparent' : 'white'"
    fixed
    flat
  >
    <v-toolbar-side-icon>
      <v-img
        :src="require('@/assets/Logo.png')"
        height="50px"
        contain
      />
    </v-toolbar-side-icon>
    <v-spacer />
    <v-tabs
      right
      color="info"
      class="hidden-sm-and-down"
    >
      <v-tab
        v-for="item in menu"
        :key="item.message"
        :to="item.Link"
      >
        {{ item.title }}
      </v-tab>
    </v-tabs>

    <v-menu class="hidden-md-and-up">
      <template v-slot:activator="{ on }">
        <v-btn
          class="hidden-md-and-up"
          icon
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="item in menu"
          :key="item.message"
          :to="item.Link"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
  <!--v-app-bar
      v-scroll="onScroll"
      :color="!isScrolling ? 'transparent' : 'white'"
      fixed
      flat
    >
      <v-avatar :tile="true" />
      <v-tabs
        right
        color="info"
      >
        <v-tab>Angebot</v-tab>

        <v-tab>Über Uns</v-tab>

        <v-tab>Dienstleistungen</v-tab>
      </v-tabs>
      <v-spacer />

      <v-spacer />

    </v-app-bar-->
</template>

<script>
  export default {
    name: 'CoreAppBar',

    data: () => ({
      showLogo: true,
      isScrolling: false,
      menu: [
        { icon: 'info', title: 'Über Uns', Link: '/#about' },
        { icon: 'warning', title: 'Dienstleistungen', Link: '/#services' },
        { icon: 'warning', title: 'Kontakt', Link: '/#get-in-touch' },
      ],
    }),

    methods: {
      onScroll () {
        const offset = window.pageYOffset
        this.isScrolling = offset > 50
        this.showLogo = offset > 200
      },

    },
  }
</script>

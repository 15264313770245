<template>
  <component
    :is="tag"
    class="text-h1 v-heading mb-3 font-weight-bold"
  >
    <slot />
  </component>
</template>

<script>
  export default {
    name: 'BaseHeading',

    props: {
      tag: {
        type: String,
        default: 'h1',
        align: 'center',

      },
      mobileSize: {
        type: String,
        class: 'text-h3',
      },

    },
  }
</script>

<style >

 .v-application h1.v-heading{
    letter-spacing: 3px !important;
    font-size: 5vw !important;
   line-height: 3vw!important;
}
 @media screen and (max-width: 992px) {
   .v-application h1.v-heading {
     font-size: 2rem !important;
     line-height: 2rem !important;
   }
 }
</style>

<template>
  <component
    :is="tag"
    class="text-h4 v-heading mb-3 font-weight-bolder"
  >
    <slot />
  </component>
</template>

<script>
  export default {
    name: 'BaseTitle',

    props: {
      tag: {
        type: String,
        default: 'h4',

      },
    },
  }
</script>

<style >

.v-application h4.v-heading{
  letter-spacing: 3px !important;
  font-size: 2vw !important;
  line-height: 3vw!important;
  padding-top: 25px!important;
  padding-bottom: 10px!important;
  font-weight: bold;
}
@media screen and (max-width: 992px) {
  .v-application h4.v-heading {
    font-size: 1.4rem!important;
    line-height:1.4rem!important;
  }
}
</style>
